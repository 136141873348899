<div class="w-3/4 m-auto pt-4">
    <img src="https://dev-hr.pumpumpum.com/assets/logo/logo-light.svg" alt="" [routerLink]="['/home']" class="hover:cursor-pointer hover:scale-105 duration-300">
</div>
<mat-nav-list class="mt-8">
    <div *ngFor="let folder of folders">
        <a [routerLink]="folder.path">
            <mat-list-item (click)="navigateTo(folder.path)" [ngClass]="{'selected' : selectedLink === folder.path }">
                <mat-icon class="pr-6 text-gray-300">{{folder.icon}}</mat-icon>
                <span class="pl-6 text-sm text-gray-300">{{folder.name}}</span>
            </mat-list-item>
        </a>
    </div>
</mat-nav-list>