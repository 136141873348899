import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { navList } from '../../../core/constants/navList';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  folders = navList;

  selectedLink: string = 'home';

  @Output() menuClick = new EventEmitter();

  constructor(
    private _router: Router,
  ) {
    this._router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        const separatedUrl = val.url.split('/')[1]
        this.selectedLink = separatedUrl
      }
    });
  }

  ngOnInit(): void {
    this.selectedLink = this._router.url.split('/')[1].toLowerCase();
  }

  navigateTo(path: string | undefined) {
    if (!path) return;
    this.menuClick.emit();
    this.selectedLink = path.toLowerCase();
  }

}
