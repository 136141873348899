<mat-sidenav-container hasBackdrop="false" class="h-screen">
    <mat-sidenav #sidenav mode="side" [opened]="opened" class="w-1/6 bg-[#1a1e36]">
        <app-sidenav (menuClick)="sidenav.open()"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="min-w-5/6 max-w-6/6">
        <app-header (menuClick)="sidenav.toggle()"></app-header>
        <div class="overflow-y-auto h-[90vh] bg-gray-50">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>