import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private _router: Router
  ) { }

  @Output() menuClick = new EventEmitter();

  navigateTo(path: string) {
    this._router.navigateByUrl(path);
  }
}
