import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../constants/common.const';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _authEndpoint = `${environment.apiBaseUrl}/user/v1/auth`;

  constructor(private _http: HttpClient) { }

  /**
   * @param <token>
   * @description Store user token in local storage for authenticate logged in user
   */
  setTokenInLocalStorage(token: string): void {
    localStorage.setItem(AppConstants.AUTH_TOKEN_KEY, JSON.stringify(token));
  }

  setLoginDataInLocalStorage(data: any): void {
    localStorage.setItem(AppConstants.AUTH_DATA_KEY, JSON.stringify(data));
  }

  getLoginData() {
    const data = localStorage.getItem(AppConstants.AUTH_DATA_KEY);
    return data ? JSON.parse(data) : null;
  }

  /**
   * @returns token
   */
  getToken() {
    const tokenString = localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
    return tokenString ? JSON.parse(tokenString) : null;
  }

  sendOtp(data: any): Observable<any> {
    return this._http.post(this._authEndpoint + '/send-otp', data);
  }

  verifyOtp(data: any): Observable<any> {
    return this._http.post(this._authEndpoint + '/verify-otp', data);
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      localStorage.clear();
      observer.next(true);
    });
  }
}
