import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})

export class TokenInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _authService: AuthService
    ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._authService.getToken();

    const headers: any = {
      'entityType': 'ADMIN',
    };
    if (token) headers['Authorization'] = 'Bearer ' + token;
    req = req.clone({
      setHeaders: headers,
    });
    return next.handle(req).pipe((source) => this.handleAuthError(source));
  }

  handleAuthError(source: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
    return source.pipe(
      catchError((error: HttpErrorResponse) => {
        // eslint-disable-next-line no-magic-numbers
        if ([401, 403,].includes(error.status)) {
          this._authService.logout().subscribe({
            next: () => {
              this._router.navigateByUrl('auth');
            },
          });
          return EMPTY;
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
