import { Section } from '../models/sidenav.model';
import { URL } from './urls';

export const navList: Section[] = [
    {
        name: 'Home',
        icon: 'home',
        path: URL.HOME,
        isExpanded: false,
        subFolders: [],
    },
    {
        name: 'Blog',
        icon: 'local_florist',
        path: URL.BLOG,
        isExpanded: false,
        subFolders: [],
    },
    {
        name: 'Category',
        icon: 'checklist',
        path: URL.CATEGORY,
        isExpanded: false,
        subFolders: [],
    },
]